<template>
  <div>
    <div style="margin-bottom:10px;">
      <el-button type="primary" @click="addFormVisible=true">添加产品分类</el-button>
    </div>
    <el-table
      :data="category"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      border
      default-expand-all
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="name" label="中文描述" sortable width="180"></el-table-column>
      <el-table-column prop="englishname" label="英文描述"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="编辑产品分类" :visible.sync="editFormVisible" modal :close-on-click-modal="false">
      <el-form :model="cat">
        <el-form-item label="分类英文名称" label-width="120px">
          <el-input v-model="cat.CategoryNameEng" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类中文名称" label-width="120px">
          <el-input v-model="cat.CategoryNameChn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择父级分类" label-width="120px">
          <el-select v-model="cat.ParentCategoryID" placeholder="请选择">
            <el-option :value="0" label="一级分类"></el-option>
            <el-option v-for="item in category" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" label-width="120px">
          <el-input v-model="cat.OrderNo" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加产品分类" :visible.sync="addFormVisible" modal :close-on-click-modal="false">
      <el-form :model="cat" :rules="rules" ref="addCatFormRef">
        <el-form-item label="分类英文名称" label-width="120px" prop="CategoryNameEng">
          <el-input v-model="cat.CategoryNameEng" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="分类中文名称" label-width="120px" prop="CategoryNameChn">
          <el-input v-model="cat.CategoryNameChn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择父级分类" label-width="120px" prop="ParentCategoryID">
          <el-select v-model="cat.ParentCategoryID" placeholder="请选择">
            <el-option :value="0" label="一级分类"></el-option>
            <el-option v-for="item in category" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="排序" label-width="120px" prop="OrderNo">
          <el-input v-model.number="cat.OrderNo" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">添 加</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      category: [],
      cat: {},
      editFormVisible: false,
      addFormVisible: false,
      rules: {
        ParentCategoryID: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        CategoryNameEng: [
          { required: true, message: '请输入分类英文名称', trigger: 'blur' }
        ],
        CategoryNameChn: [
          { required: true, message: '请输入分类中文名称', trigger: 'blur' }
        ],
        OrderNo: [
          {
            required:true,
            type: 'number',
            min: 0,
            message: '请输入大于0的数字',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    getcategories() {
      this.$http
        .get('/category/getallcat')
        .then(res => (this.category = res.data.data)).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    handleEdit(index, row) {
      this.editFormVisible = true
      this.$http.get('/category/getcat?cid=' + row.id).then(res => {
        this.cat = res.data.data
      }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    handleDelete(index, row) {
      this.$confirm(
        '此操作将永久删除"' +
          row.name +
          '"分类，请确保删除前必须先删除该分类下所有产品，否则将删除失败, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$http.post('/category/DeleteCat?id=' + row.id).then(res => {
            if (res.data.code != 200) {
              this.$message.error(res.data.errmsg)
            } else {
              this.$message({
                message: '删除成功！',
                type: 'success'
              })
              this.getcategories()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
      window.console.log(index, row)
    },
    save() {
      this.$http.post('/category/editcat', this.cat).then(res => {
        if (res.data.code != 200) {
          this.$message.error(res.data.errmsg)
        } else {
          this.$message({
            message: '保存成功！',
            type: 'success'
          })
          this.editFormVisible = false
          this.getcategories()
        }
      }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
    },
    add() {
      this.$refs.addCatFormRef.validate(valid => {
        if (!valid) return
        this.$http.post('/category/addcat', this.cat).then(res => {
          if (res.data.code != 200) {
            this.$message.error(res.data.errmsg)
          } else {
            this.$message({
              message: '添加成功！',
              type: 'success'
            })
            this.addFormVisible = false
            this.getcategories()
            this.cat={}
          }
        }).catch(err => {
          window.console.log(err.response)
          this.$message.error(err.response.data.Message + ',请重新登录')
          this.$router.push('/login')
        })
      })
    }
  },
  created() {
    this.getcategories()
  }
}
</script>